<script setup lang="ts">
const props = defineProps({
  summary: {
    type: String,
    default: undefined,
  },
  notificationDelaySeconds: {
    type: Number,
    default: 3,
  },
})

const router = useRouter()
let timer: ReturnType<typeof setInterval>
const renderedTimestamp = ref(Date.now())
const currentTimestamp = ref(Date.now())

const countdownSeconds = computed(() => {
  const runningSeconds = Math.floor((currentTimestamp.value - renderedTimestamp.value) / 1000)
  const countdownSeconds = props.notificationDelaySeconds - runningSeconds
  return countdownSeconds < 0 ? 0 : countdownSeconds
})

const done = function () {
  router.push('/')
}

onMounted(() => {
  timer = setInterval(() => {
    currentTimestamp.value = Date.now()
    if (countdownSeconds.value === 0) {
      clearInterval(timer)
      done()
    }
  }, 1000)
})
onUnmounted(() => {
  clearInterval(timer)
})
</script>

<template>
  <div class="w-full h-full flex flex-col items-stretch">
    <BaseBox v-if="summary" class="w-full flex-shrink-0" icon="checkmark" @icon="done">
      <BaseFormatSummary :summary="summary" />
    </BaseBox>
    <base-title title="Danke!" subtitle="Die Änderungen wurden gespeichert" class="w-full flex-1" />
  </div>
</template>
