<script setup lang="ts">
import type { Input as AntInput } from 'ant-design-vue'

type InputType = InstanceType<typeof AntInput>['$props']['type']
const props = defineProps({
  label: {
    type: String,
    default: undefined,
  },
  value: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: undefined,
  },
  isFocused: {
    type: Boolean,
    default: false,
  },
  isError: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String as PropType<InputType>,
    default: undefined,
  },
})

const emit = defineEmits<{
  (e: 'update:value', arg1: string): void
  (e: 'focus'): void
  (e: 'blur'): void
}>()

const isActuallyFocused = ref(props.isFocused)
const input = ref()

function focus() {
  isActuallyFocused.value = true
  emit('focus')
}

function blur() {
  isActuallyFocused.value = false
  emit('blur')
}

onMounted(() => {
  if (input.value && props.isFocused) {
    setTimeout(input.value.focus, 500)
  }
})

watch(
  () => props.value,
  () => {
    emit('update:value', props.value)
  }
)
</script>

<template>
  <label class="block">
    <div v-if="label" class="font-bold text-sm h-5">
      {{ label }}
    </div>
    <a-input
      ref="input"
      :value="value"
      :disabled="disabled"
      :placeholder="placeholder"
      class="Input text-2xl"
      :class="{ Focused: isActuallyFocused, Error: isError }"
      :type="type"
      @update:value="$emit('update:value', $event)"
      @focus="focus"
      @blur="blur"
    >
      <template #suffix>
        <button
          type="button"
          :class="{ 'invisible pointer-events-none': !value.length }"
          @click.stop="$emit('update:value', '')"
        >
          <base-icon class="text-neutral-500 flex-shrink-0" type="close" />
        </button>
      </template>
    </a-input>
  </label>
</template>

<style scoped>
.ant-input-affix-wrapper {
  @apply border-2 outline-none shadow-none;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  @apply Focused;
  border-right-width: 2px !important;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
  @apply Focused;
  border-right-width: 2px !important;
}
.Focused,
.Error {
  @apply border-2 outline-none shadow-none;
}
.Focused:not(.Error),
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled).Error:hover {
  @apply border-primary;
}
.Error {
  @apply border-red-500;
}
.Input :deep(input) {
  @apply text-base;
}
</style>
