import type { FlowConfig, FlowContext } from '../types'
import executeAPI from '~/helpers/executeAPI'
import Table from '~/components/base/Table.vue'
import { formatDate } from '~/helpers/table/rentals'
import type { TableColumn } from '~/components/base/Table.vue'
import calculatePageSize from '~/helpers/pagination/calculatePageSize'

export const rentalsColumn: TableColumn[] = [
  {
    title: 'Ausleihdatum',
    dataIndex: 'createdAt',
    key: 'rentedDate',
    width: 80,
    ellipsis: false,
    customRender: (record) => {
      return { children: formatDate(record.value) }
    },
  },
  {
    title: 'Standort',
    dataIndex: 'createdLocation',
    key: 'rentedLocation',
    width: 80,
    ellipsis: false,
  },
  {
    title: 'Verantwortlicher Service-Point-Mitarbeiter',
    dataIndex: ['createdBy', 'username'],
    key: 'responsibleSPE',
    width: 140,
    ellipsis: false,
  },
  {
    title: 'Mitarbeitername',
    dataIndex: 'employeeName',
    key: 'employeeName',
    width: 95,
    ellipsis: false,
  },
  // TODO: Replace it with axaWindowsId
  // {
  //   title: 'Mitarbeiter-ID',
  //   dataIndex: 'employeeId',
  //   key: 'employeeId',
  //   width: 95,
  //   ellipsis: false,
  // },
  {
    title: 'Kategorie',
    dataIndex: ['equipmentOption', 'type'],
    key: 'category',
    width: 60,
    ellipsis: false,
  },
  {
    title: 'Geräteoption',
    dataIndex: ['equipmentOption', 'title'],
    key: 'option',
    width: 80,
    ellipsis: false,
  },
]

const flow: FlowConfig = {
  id: 'statistics',
  icon: 'statistics',
  title: 'Equipment Statistik',
  tileTitle: 'Equipment Statistik',
  steps: [
    {
      id: 'statistics',
      component: Table,
      isKeyboardShown: false,
      onComponentEvents: {
        mounted: async (context: FlowContext) => {
          const currentPage = context.values.currentPage || 1
          const pageSize = calculatePageSize()
          const { rentals, count } = await executeAPI(`/api/equipment/stats?take=${pageSize}&page=${currentPage}`)
          context.store('rentals', rentals)
          context.store('totalItems', count)
          context.store('currentPage', currentPage)
          context.store('pageSize', pageSize)
        },
      },
      getComponentProps: (context: FlowContext) => ({
        columns: rentalsColumn,
        data: context.values.rentals,
        pagination: {
          currentPage: context.values.currentPage,
          pageSize: context.values.pageSize,
          totalItems: context.values.totalItems,
          onPageChange: async (page: number) => {
            context.store('currentPage', page)
            const { rentals } = await executeAPI(
              `/api/equipment/stats?take=${context.values.pageSize}&page=${page}`
            )
            context.store('rentals', rentals)
          },
        },
      }),
    },
  ],
}

export default flow
