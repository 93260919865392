import { debounce } from 'lodash'
import type { FlowConfig, FlowContext } from '../types'
import Dropdown from '~/components/base/Dropdown.vue'
import Options from '~/components/step/Options.vue'
import ConfirmationStep from '~/components/auth/ConfirmationStep.vue'
import FullscreenTextWithButton from '~/components/layout/FullscreenTextWithButton.vue'
import ThankYouScreen from '~/components/layout/ThankYouScreen.vue'
import executeAPI from '~/helpers/executeAPI'
import type { EquipmentOption } from '~/server/database/entities/EquipmentOption'
import { generateRentSummary } from '~/helpers/format/rentals'
import { formatEmployeeIntoIdAndName } from '~/helpers/format/employee'

function getEquipmentOptionById(equipmentOptions: EquipmentOption[], id: string) {
  return equipmentOptions.find(equipmentOption => equipmentOption.id === id)
}

const flow: FlowConfig = {
  id: 'onboarding',
  icon: 'onboard',
  title: 'Onboarding',
  tileTitle: 'Onboarding starten',
  steps: [
    {
      id: 'employees',
      component: Dropdown,
      isKeyboardShown: true,
      onComponentEvents: {
        'mounted': async (context: FlowContext) => {
          const { employees } = await executeAPI('/api/employees/search')
          context.store('employees', employees)
        },
        'update:value': debounce(async (context: FlowContext, input: string) => {
          context.store('lastInput', input)
          const { employees, text } = await executeAPI(`/api/employees/search?text=${input}`)
          if (context.values.lastInput === text) {
            context.store('employees', employees)
          }
        }, 300),
        'select': async (context: FlowContext, value: any) => {
          const currentEmployee = context.values.employees.find((employee: any) => employee.id === value.value)
          context.store('currentEmployee', currentEmployee)

          const rentalOptions = await executeAPI('/api/silva/options')
          context.store('rentalOptions', rentalOptions)

          const rentedEquipments = await executeAPI(`/api/silva/rentals?employeeId=${context?.values?.currentEmployee?.id}`)
          if (rentedEquipments?.length) {
            context.navigate('already-onboarded')
          }
          else {
            context.store('notebookOptions', rentalOptions.filter((option: EquipmentOption) => option.type === 'notebook'))
            if (context.values.notebookOptions.length > 1) {
              context.navigate('rent-notebook-options')
            }
            else if (context.values.notebookOptions.length < 1) {
              context.navigate('rent-headset')
            }
            else {
              context.store('selectedNotebook', context.values.notebookOptions[0])
              context.navigate('rent-notebook-serial')
            }
          }
        },
      },
      getComponentProps: (context: FlowContext) => ({
        value: context?.values?.employees?.label,
        label: 'Bitte gebe den Mitarbeiter Namen an',
        placeholder: 'Bitte gebe den Mitarbeiter Namen an',
        options: context?.values?.employees
          ? context?.values?.employees.map((employee: any) => ({ label: formatEmployeeIntoIdAndName(employee), value: employee?.id }))
          : [],
      }),
    },
    {
      id: 'already-onboarded',
      component: FullscreenTextWithButton,
      onComponentEvents: {
        click: (context: FlowContext) => {
          context.navigate('employees')
        },
      },
      getComponentProps: (context: FlowContext) => ({
        title: `${context.values?.currentEmployee?.fullName} hat das Equipment bereits erhalten`,
        subtitle: 'Bitte vergewissere dich, dass der richtige Mitarbeiter ausgewählt wurde',
        icon: 'back',
        buttonTitle: 'Zurück',
      }),
    },
    {
      id: 'rent-notebook-options',
      component: Options,
      isKeyboardShown: true,
      onComponentEvents: {
        mounted(context: FlowContext) {
          context.store('selectedNotebook', undefined)
        },
        select: (context: FlowContext, value: any) => {
          context.store('selectedNotebook', getEquipmentOptionById(context.values.rentalOptions, value.value))
          context.navigate('rent-notebook-serial')
        },
        back: (context: FlowContext) => {
          context.navigate('employees')
        },
      },
      getComponentProps: (context: FlowContext) => ({
        type: 'dropdown',
        value: context?.values?.rentalOptions?.label,
        label: 'Bitte wähle das Notebook Model aus',
        placeholder: 'Bitte wähle das Notebook Model aus',
        isNextButtonDisabled: !(context?.values?.selectedNotebook),
        options: context?.values?.notebookOptions.map((equipment: any) => ({ label: equipment?.title, value: equipment?.id })),
      }),
    },
    {
      id: 'rent-notebook-serial',
      component: Options,
      isKeyboardShown: true,
      onComponentEvents: {
        'mounted': (context: FlowContext) => {
          context.store('selectedNotebookSerial', '')
          context.store('selectedEquipmentsToRent', [])
        },
        'update:value': (context: FlowContext, value: any) => {
          context.store('selectedNotebookSerial', value)
        },
        'back': (context: FlowContext) => {
          if (context?.values?.notebookOptions.length > 1) {
            context.navigate('rent-notebook-options')
          }
          else {
            context.navigate('employees')
          }
        },
        'next': (context: FlowContext) => {
          context.store('selectedEquipmentsToRent', [...(context?.values?.selectedEquipmentsToRent || []), { ...context.values.selectedNotebook, serial: context.values.selectedNotebookSerial },])
          context.navigate('rent-headset')
        },
      },
      getComponentProps: (context: FlowContext) => ({
        type: 'text',
        value: context.values.selectedNotebook,
        nextButtonText: 'Nächster Schritt',
        isNextButtonDisabled: !(context?.values?.selectedNotebookSerial),
        label: 'Bitte gebe die NotebookID an',
        placeholder: 'Bitte gebe die NotebookID an',
      }),
    },
    {
      id: 'rent-headset',
      component: Options,
      isKeyboardShown: true,
      onComponentEvents: {
        select: (context: FlowContext, value: any) => {
          context.store('selectedEquipmentsToRent', [...(context?.values?.selectedEquipmentsToRent || []), (getEquipmentOptionById(context.values.rentalOptions, value.value)),])
          context.navigate('rent-keyboard')
        },
        back: (context: FlowContext) => {
          if (context?.values?.notebookOptions.length > 0) {
            context.navigate('rent-notebook-serial')
          }
          else {
            context.navigate('employees')
          }
        },
        next: (context: FlowContext) => {
          context.navigate('rent-keyboard')
        },
      },
      getComponentProps: (context: FlowContext) => {
        const headsetOptions = context?.values?.rentalOptions.filter((equipment: EquipmentOption) => equipment.type === 'headset')
        return {
          type: 'dropdown',
          value: context?.values?.rentalOptions?.label,
          label: 'Bitte wähle das Headset Model aus',
          placeholder: 'Bitte wähle das Headset Model aus',
          isNextButtonDisabled: !!headsetOptions.length,
          options: headsetOptions ? headsetOptions.map((equipment: EquipmentOption) => ({ label: equipment?.title, value: equipment?.id })) : [],
        }
      },
    },
    {
      id: 'rent-keyboard',
      component: Options,
      isKeyboardShown: true,
      onComponentEvents: {
        select: (context: FlowContext, value: any) => {
          context.store('selectedEquipmentsToRent', [...(context?.values?.selectedEquipmentsToRent || []), (getEquipmentOptionById(context.values.rentalOptions, value.value)),])
          context.navigate('onboarding-confirmation')
        },
        back: (context: FlowContext) => {
          context.navigate('rent-headset')
        },
        next: (context: FlowContext) => {
          context.navigate('onboarding-confirmation')
        },
      },
      getComponentProps: (context: FlowContext) => {
        const keyboardOptions = context?.values?.rentalOptions.filter((equipment: EquipmentOption) => equipment.type === 'keyboard')
        return {
          type: 'dropdown',
          value: context?.values?.rentalOptions?.label,
          label: 'Bitte wähle das Tastatur Model aus',
          placeholder: 'Bitte wähle das Tastatur Model aus',
          isNextButtonDisabled: !!keyboardOptions.length,
          options: keyboardOptions ? keyboardOptions.map((equipment: EquipmentOption) => ({ label: equipment?.title, value: equipment?.id })) : [],
        }
      },
    },
    {
      id: 'onboarding-confirmation',
      component: ConfirmationStep,
      isKeyboardShown: false,
      onComponentEvents: {
        mounted(context: FlowContext) {
          context.store('summary', generateRentSummary(context?.values?.currentEmployee?.fullName, context?.values?.selectedEquipmentsToRent) || '')
        },
        confirm: async (context: FlowContext, value: any) => {
          const rentalRequestData = {
            employeeId: context?.values?.currentEmployee?.id,
            rentals: context?.values?.selectedEquipmentsToRent.map((equipmentOption: EquipmentOption) => ({
              equipmentId: equipmentOption.id,
              ...equipmentOption,
            })),
          }
          await executeAPI('/api/silva/rentals', { method: 'POST', data: rentalRequestData, cardId: value })
          context.navigate('thankyou')
        },
        back: (context: FlowContext) => {
          context.navigate('employees')
        },
      },
      getComponentProps: (context: FlowContext) => ({
        summary: context.values.summary || '',
      }),
    },
    {
      id: 'thankyou',
      component: ThankYouScreen,
      getComponentProps: (context: FlowContext) => ({
        summary: context.values.summary || '',
      }),
    },
  ],
}

export default flow
