<script setup lang="ts">
const props = defineProps({
  dots: {
    type: Number,
    default: 3,
  },
})

const visibleDots = ref(0)
let interval: ReturnType<typeof setInterval>

onMounted(() => {
  if (interval) {
    clearInterval(interval)
  }
  interval = setInterval(() => {
    visibleDots.value = (visibleDots.value + 1) % props.dots
  }, 500)
})
onUnmounted(() => {
  if (interval) {
    clearInterval(interval)
  }
})
</script>

<template>
  <span>
    Loading<span v-for="index of dots" :key="index" :class="{ 'opacity-10': index - 1 > visibleDots }">.</span>
  </span>
</template>
