import type { FlowConfig } from '../types'

const flow: FlowConfig = {
  id: 'help',
  icon: 'help',
  title: 'Weitere Hilfe anfordern',
  tileTitle: 'Weitere Hilfe anfordern',
}

export default flow
