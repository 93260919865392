import type { FlowConfig, FlowContext } from '../types'
import Tiles from '~/components/base/Tiles.vue'
import Options from '~/components/step/Options.vue'
import AuthCard from '~/components/auth/Card.vue'
import PasswordComparison from '~/components/auth/PasswordComparison.vue'
import ConfirmationStep from '~/components/auth/ConfirmationStep.vue'
import ThankYouScreen from '~/components/layout/ThankYouScreen.vue'
import executeAPI from '~/helpers/executeAPI'
import useAuthStore from '~/composables/useAuthStore'

const createOrDeleteUserTilesConfig = [
  { id: 'create', title: 'Nutzer anlegen', icon: 'addUser', disabled: false },
  { id: 'delete', title: 'Nutzer löschen', icon: 'deleteUser', disabled: false },
]

const flow: FlowConfig = {
  id: 'manageUser',
  icon: 'manageUser',
  title: 'Nutzerverwalten',
  tileTitle: 'Nutzer verwalten',
  isAdmin: true,
  steps: [
    {
      id: 'options',
      component: Tiles,
      isKeyboardShown: false,
      onComponentEvents: {
        click: (context: FlowContext, tileId) => {
          if (tileId === 'create') {
            context.navigate('create-user')
            return
          }
          context.navigate('delete-user')
        },
      },
      getComponentProps: () => ({
        allTiles: createOrDeleteUserTilesConfig,
      }),
    },
    {
      id: 'create-user',
      component: Options,
      isKeyboardShown: true,
      onComponentEvents: {
        'next': (context: FlowContext) => {
          context.navigate('card-register')
        },
        'update:value': (context: FlowContext, value: string) => {
          context.store('newUsername', value?.toLowerCase())
        },
      },
      getComponentProps: (context: FlowContext) => ({
        type: 'text',
        label: 'Bitte gebe den Nutzernamen an',
        placeholder: 'Bitte gebe den Nutzernamen an',
        isNextButtonDisabled: !(context?.values?.newUsername),
        isBackButtonVisible: false,
      }),
    },
    {
      id: 'card-register',
      component: AuthCard,
      isKeyboardShown: false,
      onComponentEvents: {
        submit: (context: FlowContext, value: any) => {
          context.store('newCardId', value)
          context.navigate('set-password')
        },
      },
      getComponentProps: () => ({
        title: 'Karte registrieren',
        description: 'Bitte scanne den Mitarbeiterausweis des neuen Nutzers um die Karte zu registrieren',
        isActive: true,
      }),
    },
    {
      id: 'set-password',
      component: PasswordComparison,
      isKeyboardShown: true,
      onComponentEvents: {
        back: (context: FlowContext) => {
          context.navigate('card-register')
        },
        next: (context: FlowContext) => {
          context.store('summary', `Neuen Nutzer "${context?.values?.newUsername}" anlegen`)
          context.navigate('confirm-new-user')
        },
        change: (context: FlowContext, value: string) => {
          context.store('password', value)
        },
      },
      getComponentProps: () => ({}),
    },
    {
      id: 'confirm-new-user',
      component: ConfirmationStep,
      isKeyboardShown: false,
      onComponentEvents: {
        confirm: async (context: FlowContext, value: any) => {
          const newUser = {
            username: context.values.newUsername,
            password: context.values.password,
            cardId: context.values.newCardId,
          }
          await executeAPI('api/user/create', { data: newUser, method: 'POST', cardId: value })
          context.navigate('thanks')
        },
        back: (context: FlowContext) => {
          context.navigate('create-user')
        },
      },
      getComponentProps: (context: FlowContext) => ({
        summary: context.values.summary || '',
      }),
    },
    {
      id: 'delete-user',
      component: Options,
      isKeyboardShown: false,
      onComponentEvents: {
        mounted: async (context: FlowContext) => {
          context.store('selectedUsersToDelete', {})
          const userList = await executeAPI('api/user/list')
          context.store('userList', userList)
        },
        select: (context: FlowContext, value: any) => {
          context.store('selectedUsersToDelete', value)
          context.store('summary', `Folgende Nutzer werden gelöscht und haben keinen Zugriff mehr auf den Service Point: \n- ${context?.values?.selectedUsersToDelete.value}`)
          context.navigate('confirm-delete-user')
        },
        back: (context: FlowContext) => {
          context.navigate('options')
        },
      },
      getComponentProps: (context: FlowContext) => ({
        type: 'dropdown',
        label: 'Bitte wähle den Nutzer aus, den du löschen willst',
        isNextButtonDisabled: true,
        selected: context.values.selectedUsersToDelete,
        options: context?.values?.userList
          ? context?.values?.userList.filter((user: any) => (user.username !== useAuthStore().session.value.username))
            .map((user: any) => ({ label: user?.username, value: user?.username }))
          : [],
      }),
    },
    {
      id: 'confirm-delete-user',
      component: ConfirmationStep,
      isKeyboardShown: false,
      onComponentEvents: {
        confirm: async (context: FlowContext, value: any) => {
          await executeAPI('api/user/remove', { data: { username: context.values.selectedUsersToDelete.value }, method: 'DELETE', cardId: value })
          context.navigate('thanks')
        },
        back: (context: FlowContext) => {
          context.navigate('delete-user')
        },
      },
      getComponentProps: (context: FlowContext) => ({
        summary: context.values.summary || '',
      }),
    },
    {
      id: 'thanks',
      component: ThankYouScreen,
      getComponentProps: (context: FlowContext) => ({
        summary: context.values.summary || '',
      }),
    },
  ],
}

export default flow
