<script setup lang="ts">
import Button from './Button.vue'

const props = defineProps({
  label: {
    type: String,
    default: undefined,
  },
  value: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: undefined,
  },
  isFocused: {
    type: Boolean,
    default: false,
  },
  isError: {
    type: Boolean,
    default: false,
  },
  rows: {
    type: Number,
    default: 5,
  },
  minRows: {
    type: Number,
    default: 5,
  },
  maxRows: {
    type: Number,
    default: 5,
  },
})

const emit = defineEmits<{
  (e: 'update:value', arg1: string): void
  (e: 'focus'): void
  (e: 'blur'): void
}>()

const isActuallyFocused = ref(props.isFocused)
const textarea = ref()

function focus() {
  isActuallyFocused.value = true
  emit('focus')
}

function blur() {
  isActuallyFocused.value = false
  emit('blur')
}

onMounted(() => {
  if (textarea.value && props.isFocused) {
    setTimeout(textarea.value.focus, 500)
  }
})

watch(
  () => props.value,
  () => {
    emit('update:value', props.value)
  }
)
</script>

<template>
  <label class="block">
    <div v-if="label" class="font-bold text-sm h-5">
      {{ label }}
    </div>
    <div class="relative">
      <a-textarea
        ref="textarea"
        :value="value"
        :disabled="disabled"
        :placeholder="placeholder"
        :auto-size="{ minRows, maxRows }"
        class="Input Textarea text-2xl"
        :class="{ Focused: isActuallyFocused, Error: isError }"
        :rows="rows"
        @update:value="$emit('update:value', $event)"
        @focus="focus"
        @blur="blur"
      />
      <Button
        v-if="!isActuallyFocused && value"
        size="small"
        class="absolute right-2 bottom-2"
        data-testid="clear-button"
        @click="$emit('update:value', '')"
      >
        Löschen
      </Button>
    </div>
  </label>
</template>

    <style scoped>
    .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
        @apply border-primary;
    }
    .Focused,
    .Error {
    @apply border-2 outline-none shadow-none;
    }
    .Focused:not(.Error),
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled).Error:hover {
    @apply border-primary;
    }
    .Textarea {
    @apply border-2
    }
    .Textarea :deep(textarea) {
    @apply text-base;
    }
    .Textarea:hover {
    @apply border-primary;
    }
    </style>
