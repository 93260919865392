<script setup lang="ts">
const props = defineProps({
  summary: {
    type: String,
    required: true,
  },
})

const lines = computed(() => props.summary.split(/\r?\n/).filter(str => str))
</script>

<template>
  <div>
    <div v-for="line of lines" :key="line">
      {{ line }}
    </div>
  </div>
</template>
