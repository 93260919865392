<script setup lang="ts">
import Box from '~/components/base/Box.vue'

export declare interface SelectOption {
  label: string
  value: string
  disabled?: boolean
}

const props = defineProps({
  options: {
    type: Array as PropType<SelectOption[]>,
    default: () => [],
  },
  selected: {
    type: String,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: undefined,
  },
  title: {
    type: String,
    default: 'Bitte wähle die Gegenstände aus',
  },
  layout: {
    type: String as PropType<'vertical' | 'horizontal'>,
    default: 'vertical',
  },
  boxContainer: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits<{
  (e: 'update:selected', args1: string): void
  (e: 'select', args1: string): void
}>()

const sortedOptions = computed(() => {
  return [...props.options].sort((a, b) => {
    if (!a?.disabled && b?.disabled) {
      return -1
    }
    return 0
  })
})

const radioStyle = computed(() => {
  if (props.layout === 'vertical') {
    return {
      display: 'flex',
      height: '30px',
      lineHeight: '30px',
    }
  }
  return { }
})

function selectItem(value: string) {
  emit('update:selected', value)
  emit('select', value)
}
</script>

<template>
  <component
    :is="boxContainer ? Box : 'div'"
    :title="boxContainer ? title : undefined"
    class="w-full h-full flex flex-col justify-stretch max-h-full"
  >
    <div class="ChecklistContainer w-full h-full flex items-center max-h-full">
      <span v-if="errorMessage" class="NotificationText ErrorText">{{ errorMessage }}</span>
      <base-text-with-icon
        v-else-if="isLoading"
        type="spin"
        class="NotificationText fill-current gap-x-2"
        icon-position="left"
      >
        <span class="font-medium">Lade ...</span>
      </base-text-with-icon>
      <span v-else-if="!options.length" class="NotificationText">Keine Daten gefunden</span>
      <a-radio-group v-else :value="selected" @update:value="selectItem">
        <a-radio
          v-for="option in sortedOptions"
          :key="option.value"
          :value="option.value"
          :disabled="option.disabled"
          :style="radioStyle"
        >
          {{ option.label }}
        </a-radio>
      </a-radio-group>
    </div>
  </component>
</template>

<style scoped>
.ChecklistContainer .NotificationText {
  @apply font-medium text-neutral-400 text-base;
}
.ChecklistContainer .NotificationText.ErrorText {
  @apply text-red-600;
}
.ChecklistContainer :deep(.ant-checkbox-group) {
  @apply flex;
}
.ChecklistContainer :deep(.ant-checkbox-wrapper) {
  @apply ml-0 text-base;
}
</style>
