<script setup lang="ts">
import BackNextButtons from '../base/BackNextButtons.vue'

const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  password: {
    type: String,
    default: '',
  },
  isBackButtonVisible: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['back', 'next', 'update:value', 'change'])
const currentlyActiveInput = ref()
const localPassword = ref(props.password)
const passwordCheck = ref('')
const arePasswordsMatch = computed(() => {
  return passwordCheck.value && localPassword.value === passwordCheck.value
})

watch([localPassword, passwordCheck, currentlyActiveInput], () => {
  if (currentlyActiveInput.value === 'password') {
    emit('update:value', localPassword.value)
    emit('change', localPassword.value)
  }
  else if (currentlyActiveInput.value === 'passwordCheck') {
    emit('update:value', passwordCheck.value)
  }
  else {
    emit('update:value')
  }
})

watch(
  () => props.value,
  () => {
    if (currentlyActiveInput.value === 'password') {
      localPassword.value = props.value
    }
    else if (currentlyActiveInput.value === 'passwordCheck') {
      passwordCheck.value = props.value
    }
  }
)
</script>

<template>
  <div class="flex flex-col gap-y-2.5">
    <BaseInput
      v-model:value="localPassword"
      label="Bitte lege ein Passwort fest"
      placeholder="Bitte lege ein Passwort fest"
      type="password"
      is-focused
      @focus="currentlyActiveInput = 'password'"
      @blur="currentlyActiveInput = undefined"
    />
    <BaseInput
      v-model:value="passwordCheck"
      label="Bitte wiederhole das Passwort"
      placeholder="Bitte wiederhole das Passwort"
      type="password"
      :is-alerted="!!passwordCheck && !arePasswordsMatch"
      @focus="currentlyActiveInput = 'passwordCheck'"
      @blur="currentlyActiveInput = undefined"
    />
    <BackNextButtons
      :is-next-button-disabled="!arePasswordsMatch"
      :is-back-button-visible="isBackButtonVisible"
      @back="$emit('back')"
      @next="$emit('next')"
    />
  </div>
</template>
