import { v4 as uuidv4 } from 'uuid'
import diff from 'diff-arrays-of-objects'
import type { DropdownOption } from '../components/base/Dropdown.vue'
import type { FlowConfig, FlowContext } from '../types'
import type { EquipmentOptionFull, EquipmentOptionNew } from '../server/database/entities/EquipmentOption'
import Tiles from '~/components/base/Tiles.vue'
import Options from '~/components/step/Options.vue'
import ConfirmationStep from '~/components/auth/ConfirmationStep.vue'
import ThankYouScreen from '~/components/layout/ThankYouScreen.vue'
import executeAPI from '~/helpers/executeAPI'

interface UniqueEquipmentType {
  label: string
  value?: string
}

interface EquipmentDiff {
  added: EquipmentOptionNew[]
  removed: EquipmentOptionFull[]
  same: EquipmentOptionFull[]
  updated: EquipmentOptionFull[]
}

const { session } = useAuthStore()
const EQUIPMENT_OPTIONS_URL = '/api/equipment/options'

const createOrDeleteEquipmentOptionsTilesConfig = [
  { id: 'create', title: 'Equipment anlegen', icon: 'equipmentAdd', disabled: false },
  { id: 'change', title: 'Equipment ändern', icon: 'equipmentSettings', disabled: false },
  { id: 'delete', title: 'Equipment löschen', icon: 'equipmentRemove', disabled: false },
]

/**
 * Fetch all equipment options.
 */
const getEquipmentTypeOptions = async function (activeEquipment?: boolean): Promise<EquipmentOptionFull[]> {
  let query = `?locationId=${session.value.location.id}`
  if (activeEquipment) {
    query += `&active=${activeEquipment}`
  }
  return await executeAPI(`${EQUIPMENT_OPTIONS_URL}${query}`)
}

/**
 * Give back the unique equipment types from a given list of `EquipmentOptionFull`.
 */
const getUniqueEquipmentTypeOptions = function (equipmentOptions: EquipmentOptionFull[]) {
  const result: UniqueEquipmentType[] = Array
    .from(new Set(equipmentOptions.map(equipment => (equipment.type))))
    .map((uniqueType: string) => ({ label: uniqueType, value: uuidv4() }))
    .sort((a, b) => (a.label.localeCompare(b.label)))
  return result
}

const flow: FlowConfig = {
  id: 'manageEquipment',
  icon: 'equipment',
  title: 'Equipmentverwaltung',
  tileTitle: 'Equipment verwalten',
  isAdmin: true,
  steps: [
    {
      id: 'optionsEquipment',
      component: Tiles,
      isKeyboardShown: false,
      onComponentEvents: {
        click: (context: FlowContext, tileId: string) => {
          if (tileId === 'delete') {
            context.store('equipmentFlowAction', 'delete')
            context.navigate('deleteEquipment')
          }
          else {
            context.store('equipmentFlowAction', 'create')
            context.navigate('createEquipmentSelectType')
          }
        },
      },
      getComponentProps: () => ({ allTiles: createOrDeleteEquipmentOptionsTilesConfig }),
    },
    {
      id: 'createEquipmentSelectType',
      component: Options,
      isKeyboardShown: true,
      onComponentEvents: {
        'mounted': async (context: FlowContext) => {
          // initialize
          context.store('selectedOptionType', undefined)
          // get options for input
          const equipmentOptions = await getEquipmentTypeOptions()
          context.store('equipmentOptions', equipmentOptions)
          const uniqueOptionTypes = getUniqueEquipmentTypeOptions(equipmentOptions)
          context.store('uniqueOptionTypes', uniqueOptionTypes)
        },
        'select': (context: FlowContext, selectedEquipmentType: DropdownOption) => {
          context.store('selectedOptionType', selectedEquipmentType)
          context.navigate('createEquipmentSelectOptions')
        },
        'back': (context: FlowContext) => context.navigate('optionsEquipment'),
        'next': (context: FlowContext) => context.navigate('createEquipmentSelectOptions'),
        'update:value': (context: FlowContext, selectedEquipmentType: string) => context.store('selectedOptionType', { label: selectedEquipmentType }),
      },
      getComponentProps: (context: FlowContext) => {
        const uniqueOptionTypes: UniqueEquipmentType[] = context?.values?.uniqueOptionTypes
        const selectedOptionType: UniqueEquipmentType = context?.values?.selectedOptionType
        const equipmentTypeExists = uniqueOptionTypes?.some(uniqueType => (uniqueType.label === selectedOptionType?.label))
        const typeOfAction = equipmentTypeExists ? 'Erweitere' : 'Erstelle neue'
        const categoryText = selectedOptionType?.label !== '' ? `"${selectedOptionType?.label}"` : '...'

        const nextButtonText = `${typeOfAction} Kategorie ${categoryText}`
        const options = uniqueOptionTypes?.map(equipmentType => ({ ...equipmentType })) || []
        const isNextButtonDisabled = !selectedOptionType || selectedOptionType?.label === ''

        return {
          type: 'dropdown',
          label: 'Bitte gebe den Namen der Equipment Kategorie an (im Singular)',
          placeholder: 'Bitte gebe den Namen der Equipment Kategorie an (im Singular)',
          isNextButtonDisabled,
          nextButtonText,
          options,
        }
      },
    },
    {
      id: 'createEquipmentSelectOptions',
      component: Options,
      isKeyboardShown: true,
      onComponentEvents: {
        'mounted': (context: FlowContext) => {
          // initialize
          const equipmentOptions: EquipmentOptionFull[] = context?.values?.equipmentOptions
          const selectedOptionType: UniqueEquipmentType = context?.values?.selectedOptionType
          const initialOptionsForCurrentType = equipmentOptions?.filter(equipment => (equipment.type === selectedOptionType?.label))
          context.store('initialOptionsForCurrentType', initialOptionsForCurrentType)
          context.store('extendedOptionsForCurrentType', initialOptionsForCurrentType?.map(dataToCopy => ({ ...dataToCopy })))

          context.store('optionsToCreate', [])
          context.store('optionsToUpdate', [])

          const selectDefaultValues = (
            equipmentOptions?.filter(equipment => (equipment?.type === selectedOptionType?.label && equipment?.active === true)).map(equipment => ({ label: equipment?.title, value: equipment?.id || equipment?.title })).sort((equipmentA, equipmentB) => (equipmentA.label.localeCompare(equipmentB.label)))
            || []
          )
          context.store('selectedEquipment', selectDefaultValues)
        },
        'back': (context: FlowContext) => context.navigate('createEquipmentSelectType'),
        'next': (context: FlowContext) => {
          const initialOptionsForCurrentType: EquipmentOptionFull[] = context?.values?.initialOptionsForCurrentType
          const extendedOptionsForCurrentType: Array<EquipmentOptionFull | EquipmentOptionNew> = context?.values?.extendedOptionsForCurrentType
          const selectedOptionType: UniqueEquipmentType = context?.values?.selectedOptionType
          const uniqueOptionTypes: UniqueEquipmentType[] = context?.values?.uniqueOptionTypes

          const equipmentDiff: EquipmentDiff = diff(initialOptionsForCurrentType, extendedOptionsForCurrentType as any, 'title')

          context.store('optionsToCreate', equipmentDiff.added)
          context.store('optionsToUpdate', equipmentDiff.updated)

          const newValueGiven = uniqueOptionTypes.some(equipment => (equipment.value === selectedOptionType?.value))
          const valueExistsOrNew = newValueGiven ? 'Bestehende' : 'Neue'
          const valueChangedOrCreated = newValueGiven ? 'verändert' : 'erstellt'
          const equipmentUpdatedAndAddedAsString = [...equipmentDiff.updated, ...equipmentDiff.added].map(entity => (entity.title)).join('\n- ')
          const summary = `${valueExistsOrNew} Equipment Kategorie "${selectedOptionType?.label}" wird ${valueChangedOrCreated} zusammen mit folgenden Optionen:\n- ${equipmentUpdatedAndAddedAsString}`
          context.store('summary', summary)

          context.navigate('confirm')
        },
        'select': (context: FlowContext, selectedOption: DropdownOption) => {
          const extendedOptionsForCurrentType: Array<EquipmentOptionFull | EquipmentOptionNew> = context?.values?.extendedOptionsForCurrentType
          const editedOption = extendedOptionsForCurrentType?.find(equipment => (equipment?.id && (equipment?.id === selectedOption?.value)) || equipment.title.trim().toLocaleLowerCase() === selectedOption?.value?.trim().toLocaleLowerCase())
          const selectedEquipment = context?.values?.selectedEquipment
          selectedEquipment.push({ label: selectedOption?.label, value: editedOption?.id || selectedOption.value })

          if (editedOption) {
            editedOption.active = true
            return
          }

          const selectedNotExistingOption = !extendedOptionsForCurrentType?.some(equipment => (equipment.title === selectedOption.label))
          const selectedOptionType: UniqueEquipmentType = context?.values?.selectedOptionType
          if (selectedNotExistingOption) {
            extendedOptionsForCurrentType?.push({ title: selectedOption?.label, type: selectedOptionType?.label, active: true, locationId: session.value.location.id })
          }
        },
        'deselect': (context: FlowContext, deselectedOption: DropdownOption) => {
          const selectedEquipment = context?.values?.selectedEquipment
          const extendedOptionsForCurrentType: Array<EquipmentOptionFull | EquipmentOptionNew> = context?.values?.extendedOptionsForCurrentType
          const editedOption = extendedOptionsForCurrentType?.find(equipment => equipment.id === deselectedOption.value || equipment.title === deselectedOption.label)
          if (editedOption?.id) {
            editedOption.active = false
          }
          else {
            context.store('extendedOptionsForCurrentType', extendedOptionsForCurrentType.filter(equipment => equipment.title !== deselectedOption.value))
          }

          context.store('selectedEquipment', selectedEquipment.filter((equipment: DropdownOption) => equipment.value !== deselectedOption.value))
        },
        'update:value': (context: FlowContext, text: string) => {
          context.store('newOptionInput', text)
        },
      },
      getComponentProps: (context: FlowContext) => {
        const selectedOptionType: UniqueEquipmentType = context?.values?.selectedOptionType
        const extendedOptionsForCurrentType: Array<EquipmentOptionFull | EquipmentOptionNew> = context?.values?.extendedOptionsForCurrentType
        const initialOptionsForCurrentType: EquipmentOptionFull[] = context?.values?.initialOptionsForCurrentType
        const allEquipmentDevices = extendedOptionsForCurrentType?.map(equipment => ({ label: equipment.title, value: equipment?.id || equipment.title })) || []
        const isNextButtonDisabled = diff(initialOptionsForCurrentType, extendedOptionsForCurrentType).same.length === extendedOptionsForCurrentType?.length || !!context.values.newOptionInput
        const selectedEquipment = context?.values?.selectedEquipment || []

        return {
          type: 'multiDropdown',
          selectMode: 'tags',
          label: `Bitte wähle alle gewünschten Geräteoptionen für die Kategorie "${selectedOptionType?.label}" aus`,
          placeholder: `Bitte wähle alle gewünschten Geräteoptionen für die Kategorie "${selectedOptionType?.label}" aus`,
          options: allEquipmentDevices,
          isNextButtonDisabled,
          selectedOptions: selectedEquipment,
        }
      },
    },
    {
      id: 'deleteEquipment',
      component: Options,
      isKeyboardShown: true,
      onComponentEvents: {
        mounted: async (context: FlowContext) => {
          // initialize
          const equipmentOptions = await getEquipmentTypeOptions(true)
          context.store('equipmentOptions', equipmentOptions)
          context.store('uniqueOptionTypes', getUniqueEquipmentTypeOptions(equipmentOptions))
        },
        select: (context: FlowContext, selectedOptionToDelete: DropdownOption) => {
          context.store('equipmentCategorySelectedTypeToDelete', selectedOptionToDelete)
          context.store('summary', `Equipment Kategorie "${selectedOptionToDelete.label}" wird gelöscht.`)
          context.navigate('confirm')
        },
        back: (context: FlowContext) => context.navigate('optionsEquipment'),
        submit: (context: FlowContext) => context.navigate('confirm'),
      },
      getComponentProps: (context: FlowContext) => {
        const uniqueOptionTypes: UniqueEquipmentType[] = context?.values?.uniqueOptionTypes
        const uniqueOptionTypesOpitons = uniqueOptionTypes?.map(equipmentType => ({ label: equipmentType.label, value: equipmentType.value })) || []
        return {
          type: 'dropdown',
          label: 'Bitte gebe den Namen der Equipment Kategorie an (im Singular)',
          placeholder: 'Bitte gebe  den Namen der Equipment Kategorie an (im Singular)',
          isNextButtonVisible: false,
          options: uniqueOptionTypesOpitons,
        }
      },
    },
    {
      id: 'confirm',
      component: ConfirmationStep,
      isKeyboardShown: false,
      onComponentEvents: {
        confirm: async (context: FlowContext, cardId: string) => {
          const equipmentFlowAction: string = context?.values?.equipmentFlowAction
          // delete
          if (equipmentFlowAction === 'delete') {
            const equipmentCategorySelectedTypeToDelete: UniqueEquipmentType = context?.values?.equipmentCategorySelectedTypeToDelete
            await executeAPI(
              EQUIPMENT_OPTIONS_URL,
              { data: { type: equipmentCategorySelectedTypeToDelete?.label, locationId: session.value.location.id }, method: 'DELETE', cardId },
            )
          }

          // update / create
          if (equipmentFlowAction === 'create') {
            // fire away the data to create
            const optionsToCreate: EquipmentOptionNew[] = context?.values?.optionsToCreate
            await Promise.all(optionsToCreate?.map((equipment) => {
              return executeAPI(EQUIPMENT_OPTIONS_URL, { data: { ...equipment, locationId: session.value.location.id }, method: 'POST', cardId })
            }))

            // fire away the data to update
            const optionsToUpdate: EquipmentOptionFull[] = context?.values?.optionsToUpdate
            await executeAPI(EQUIPMENT_OPTIONS_URL, { data: optionsToUpdate, method: 'PATCH', cardId })
          }

          context.navigate('thanks')
        },
        back: (context: FlowContext) => context.navigate(context?.values?.equipmentFlowAction === 'delete' ? 'deleteEquipment' : 'createEquipmentSelectOptions'),
      },
      getComponentProps: (context: FlowContext) => ({ summary: context?.values?.summary || '' }),
    },
    {
      id: 'thanks',
      component: ThankYouScreen,
      getComponentProps: (context: FlowContext) => ({ summary: context?.values?.summary || '' }),
    },
  ],
}

export default flow
