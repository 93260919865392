<script setup lang="ts">
import type { CheckboxValueType } from 'ant-design-vue/es/checkbox/interface'
import { z } from 'zod'
import BackNextButtons from '../base/BackNextButtons.vue'
import type { DropdownOption } from '../base/Dropdown.vue'
import Dropdown from '../base/Dropdown.vue'
import Checklist from '../base/Checklist.vue'
import Input from '../base/Input.vue'
import MultiDropdown from '../base/MultiDropdown.vue'
import TextArea from '../base/TextArea.vue'

const props = defineProps({
  type: {
    type: String,
    required: true,
    validator: (type: string) => ['dropdown', 'checklist', 'text', 'multiDropdown', 'textArea'].includes(type),
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: undefined,
  },
  placeholder: {
    type: String,
    default: undefined,
  },
  options: {
    type: Array as PropType<DropdownOption[]>,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: undefined,
  },
  value: {
    type: String,
    default: '',
  },
  selected: {
    type: Array as PropType<CheckboxValueType[]>,
    default: () => [],
  },
  hasNavigationButtons: {
    type: Boolean,
    default: true,
  },
  selectedOptions: {
    type: Array as PropType<DropdownOption[]>,
    default: undefined,
  },
  ...BackNextButtons.props,
})

defineEmits(['deselect', 'select', 'submit', 'back', 'next', 'update:value'])

const emailSchema = z.string().email().or(z.literal(''))
const isEmailValid = computed(() => {
  if (props.type === 'email') {
    return emailSchema.safeParse(props.value).success
  }
  return true
})
</script>

<template>
  <div class="flex flex-col gap-8 max-h-full">
    <div class="max-h-full" :class="{ 'FullHeightWithButton': hasNavigationButtons, 'flex-1': type !== 'text' }">
      <Dropdown
        v-if="type === 'dropdown'"
        :options="options"
        :is-loading="isLoading"
        :value="value"
        :label="label"
        :disabled="disabled"
        :placeholder="placeholder"
        @select="$emit('select', $event)"
        @update:value="$emit('update:value', $event)"
      />
      <Checklist
        v-if="type === 'checklist'"
        :options="options"
        :title="label"
        :is-loading="isLoading"
        :selected="selected"
        :error-message="errorMessage"
        @select="$emit('select', $event)"
        @update:value="$emit('update:value', $event)"
      />
      <Input
        v-if="type === 'text'"
        :value="value"
        :label="label"
        :placeholder="placeholder"
        :disabled="disabled"
        @update:value="$emit('update:value', $event)"
      />
      <Input
        v-if="type === 'email'"
        :value="value"
        :label="label"
        :placeholder="placeholder"
        :disabled="disabled"
        :error-message="!isEmailValid ? 'Ungültige Email-Adresse' : ''"
        @update:value="$emit('update:value', $event)"
      />
      <TextArea
        v-if="type === 'textArea'"
        fixed
        :value="value"
        :max-rows="5"
        :min-rows="5"
        :label="label"
        :placeholder="placeholder"
        :disabled="disabled"
        @update:value="$emit('update:value', $event)"
      />
      <MultiDropdown
        v-if="type === 'multiDropdown'"
        :value="value === '' ? undefined : value"
        :label="label"
        :options="options"
        :placeholder="placeholder"
        :disabled="disabled"
        :select-mode="selectMode"
        :selected-options="selectedOptions"
        @select="$emit('select', $event)"
        @deselect="$emit('deselect', $event)"
        @update:value="$emit('update:value', $event)"
      />
    </div>
    <BackNextButtons
      v-if="hasNavigationButtons"
      :is-back-button-disabled="isBackButtonDisabled"
      :is-next-button-disabled="isNextButtonDisabled || !isEmailValid"
      :back-button-text="backButtonText"
      :next-button-text="nextButtonText"
      :is-back-button-visible="isBackButtonVisible"
      :is-next-button-visible="isNextButtonVisible"
      @back="$emit('back')"
      @next="$emit('next')"
    />
  </div>
</template>

<style scoped>
.FullHeightWithButton {
  max-height: calc(100% - 72px);
}
</style>
