import { debounce } from 'lodash'
import type { FlowConfig, FlowContext } from '../types'
import FullscreenTextWithButton from '~/components/layout/FullscreenTextWithButton.vue'
import Dropdown from '~/components/base/Dropdown.vue'
import Options from '~/components/step/Options.vue'
import ReturnAssetConsumableList, { type ReturnWithOptionalStatus } from '~/components/step/ReturnAssetConsumableList.vue'
import ConfirmationStep from '~/components/auth/ConfirmationStep.vue'
import ThankYouScreen from '~/components/layout/ThankYouScreen.vue'
import executeAPI from '~/helpers/executeAPI'
import { formatEmployeeIntoIdAndName } from '~/helpers/format/employee'
import type { AssetConsumableRentalFull } from '~/server/database/entities/AssetConsumableRental'
import { generateAssetConsumablesReturnSummary } from '~/helpers/format/assetConsumables'
import type { AssetConsumableCreateReturn } from '~/server/database/entities/AssetConsumableReturn'

const flow: FlowConfig = {
  id: 'offboarding',
  icon: 'offboard',
  title: 'Offboarding',
  tileTitle: 'Offboarding starten',
  steps: [
    {
      id: 'employees',
      component: Dropdown,
      isKeyboardShown: true,
      onComponentEvents: {
        'mounted': async (context: FlowContext) => {
          const { employees } = await executeAPI('/api/employees/search?includeInactive=true')
          context.store('employees', employees)
          context.store('lastInput', '')
        },
        'update:value': debounce(async (context: FlowContext, input: string) => {
          context.store('lastInput', input)
          const { employees, text } = await executeAPI(`/api/employees/search?text=${input}&includeInactive=true`)
          if (context.values.lastInput === text) {
            context.store('employees', employees)
          }
        }, 300),
        'select': async (context: FlowContext, value: any) => {
          const currentEmployee = context.values.employees.find((employee: any) => employee.id === value.value)
          context.store('currentEmployee', currentEmployee)

          const rentedAssetConsumables = await executeAPI(
            `/api/asset-consumable/rentals?employeeId=${context.values.currentEmployee.id}`
          )
          context.store('rentedAssetConsumables', rentedAssetConsumables.rentals)

          if (!rentedAssetConsumables?.rentals?.length) {
            context.navigate('no-rentals')
          }
          else {
            context.navigate('return-selection')
          }
        },
      },
      getComponentProps: (context: FlowContext) => ({
        value: context?.values?.employees?.label,
        label: 'Bitte gebe den Mitarbeiter Namen an',
        placeholder: 'Bitte gebe den Mitarbeiter Namen an',
        options: context?.values?.employees
          ? context?.values?.employees.map((employee: any) => ({
            label: formatEmployeeIntoIdAndName(employee),
            isSemiTransparent: !employee?.isActive,
            value: employee?.id,
          }))
          : [],
      }),
    },
    {
      id: 'no-rentals',
      component: FullscreenTextWithButton,
      onComponentEvents: {
        click: (context: FlowContext) => {
          context.navigate('employees')
        },
      },
      getComponentProps: (context: FlowContext) => ({
        title: `${context.values?.currentEmployee?.fullName} hat kein Equipment zum zurückgegeben`,
        subtitle: 'Bitte vergewissere dich, dass der richtige Mitarbeiter ausgewählt wurde',
        icon: 'back',
        buttonTitle: 'Zurück',
      }),
    },
    {
      id: 'return-selection',
      component: ReturnAssetConsumableList,
      onComponentEvents: {
        'mounted': (context: FlowContext) => {
          const returns: Record<string, ReturnWithOptionalStatus> = (context.values.rentedAssetConsumables as AssetConsumableRentalFull[])
            .reduce((obj, rental) => Object.assign(obj, {
              [rental.id]: { name: rental.name, category: rental.category, tag: rental.tag, }
            }), {})
          context.store('selectedAssetConsumablesToReturn', returns)
        },
        'update:option': (context: FlowContext, payload: { id: string, data: any }) => {
          context.store('selectedAssetConsumablesToReturn', {
            ...context?.values.selectedAssetConsumablesToReturn,
            [payload.id]: payload.data
          })
        },
        'back': (context: FlowContext) => {
          context.navigate('employees')
        },
        'next': (context: FlowContext) => {
          const filteredReturns = Object.fromEntries(
            Object.entries(context.values.selectedAssetConsumablesToReturn).filter(([, value]: [string, any]) => value.returned)
          )
          context.store('selectedAssetConsumablesToReturn', filteredReturns)
          context.navigate('return-note')
        },
      },
      getComponentProps: (context: FlowContext) => ({
        type: 'checklist',
        label: 'Bitte wähle die Gegenstände aus, die du zurückgeben wirst',
        returns: context?.values.selectedAssetConsumablesToReturn
      }),
    },
    {
      id: 'return-note',
      component: Options,
      isKeyboardShown: true,
      onComponentEvents: {
        'mounted': (context: FlowContext) => {
          context.store('returnNote', '')
        },
        'update:value': (context: FlowContext, value: any) => {
          context.store('returnNote', value)
        },
        'back': (context: FlowContext) => {
          context.navigate('return-selection')
        },
        'next': (context: FlowContext) => {
          context.navigate('confirmation-email')
        },
      },
      getComponentProps: (context: FlowContext) => ({
        type: 'textArea',
        label: 'Bitte geben Sie, falls benötigt weitere Details ein',
        placeholder: '...',
        nextButtonText: context.values?.returnNote?.length > 0 ? 'Nächster Schritt' : 'Überspringen',
      }),
    },
    {
      id: 'confirmation-email',
      component: Options,
      isKeyboardShown: true,
      onComponentEvents: {
        'mounted': (context: FlowContext) => {
          context.store('confirmationEmail', undefined)
        },
        'update:value': (context: FlowContext, value: any) => {
          context.store('confirmationEmail', value)
        },
        'back': (context: FlowContext) => {
          context.navigate('return-note')
        },
        'next': (context: FlowContext) => {
          context.navigate('return-confirmation')
        },
      },
      getComponentProps: (context: FlowContext) => ({
        type: 'email',
        value: context.values.returnEmail,
        nextButtonText: context.values?.confirmationEmail?.length > 0 ? 'Nächster Schritt' : 'Überspringen',
        label: 'Möchtest du eine Bestätigung per Email erhalten?',
        placeholder: 'Bitte gebe die Email Addresse an, an welche die Bestätigunsmail versendet werden soll.',
      }),
    },
    {
      id: 'return-confirmation',
      component: ConfirmationStep,
      isKeyboardShown: false,
      onComponentEvents: {
        mounted(context: FlowContext) {
          const assetConsumablesToReturn: AssetConsumableCreateReturn[] = Object.values(context?.values.selectedAssetConsumablesToReturn)

          context.store(
            'summary',
            generateAssetConsumablesReturnSummary(
              context?.values?.currentEmployee?.fullName,
              context?.values?.currentEmployee?.isActive,
              assetConsumablesToReturn,
              context?.values?.returnNote,
              context?.values?.confirmationEmail
            ) || ''
          )
        },
        confirm: async (context: FlowContext, value: any) => {
          await executeAPI('/api/asset-consumable/returns', {
            method: 'POST',
            data: {
              employeeId: context?.values?.currentEmployee.id,
              confirmationEmail: context?.values?.confirmationEmail,
              returnNote: context?.values?.returnNote,
              returns: Object.values(context?.values.selectedAssetConsumablesToReturn)
            },
            cardId: value,
          })
          context.navigate('thankyou')
        },
        back: (context: FlowContext) => {
          context.navigate('return-selection')
        },
      },
      getComponentProps: (context: FlowContext) => ({
        summary: context.values.summary || '',
      }),
    },
    {
      id: 'thankyou',
      component: ThankYouScreen,
      getComponentProps: (context: FlowContext) => ({
        summary: context.values.summary || '',
      }),
    },
  ],
}

export default flow
