<script setup lang="ts">
import BackNextButtons from '../base/BackNextButtons.vue'
import type { AssetConsumableCreateReturn, AssetConsumableReturnCondition, AssetConsumableReturnStatus } from '~/server/database/entities/AssetConsumableReturn'

export type ReturnWithOptionalStatus = Omit<AssetConsumableCreateReturn, 'returned'> & { returned?: AssetConsumableReturnStatus }

const props = withDefaults(defineProps<{
  returns: Record<string, ReturnWithOptionalStatus>
  hasNavigationButtons?: boolean
}>(), { hasNavigationButtons: true })

const emits = defineEmits<{
  (e: 'back'): void
  (e: 'next'): void
  (e: 'update:option', payload: { id: string, data: ReturnWithOptionalStatus }): void
}>()

const canSubmit = computed(() => {
  const allReturned = Object.values(props.returns)
  const hasReturnedValue = allReturned.some(({ returned }) => returned)

  return allReturned.every(({ category, condition, returned }) => {
    if (category === 'Consumable' && returned !== 'later') {
      if (condition && returned === undefined) {
        return false // asset condition exists but no returned value
      }
      if (!condition && returned) {
        return false // consumable with no condition shouldn't be marked as returned
      }
    }
    return true
  }) && hasReturnedValue // ensures at least one returned value is true
})
const allReturned = computed(() => {
  return Object.values(props.returns).every(({ returned, condition, category }) => {
    // for Consumable items, both 'returned' and 'condition' must be truthy
    return category === 'Consumable' ? returned && condition : returned
  })
})

const noneReturned = computed(() => {
  return Object.values(props.returns).every(({ returned, condition, category }) => {
    // for Consumable items, either returned must be undefined or 'condition' must be undefined
    // for non-consumable items, only returned must be undefined
    return category === 'Consumable' ? returned === undefined || condition === undefined : returned === undefined
  })
})
const statusOptions = [
  { label: 'Ja', value: 'yes' },
  { label: 'Nein', value: 'no' },
  { label: 'wird nachgeliefert', value: 'later' },
]

const conditionOptions = [
  { label: 'A/B', value: 'ab' },
  { label: 'C', value: 'c' },
]

function updateAssetConsumableReturn(id: string, data: ReturnWithOptionalStatus) {
  emits('update:option', { id, data })
}
</script>

<template>
  <div class="flex flex-col max-h-full">
    <div class="max-h-full flex-1 Container" :class="{ hasNavigationButtons }">
      <BaseBox title="Bitte wähle die Gegenstände aus, die du zurückgeben wirst" class="flex flex-col w-full h-full">
        <div>
          <table class="table-fixed w-full mb-2">
            <thead>
              <tr class="text-left text-xl">
                <th class="pb-5">
                  Gegenstände
                </th>
                <th class="border-l-2 pl-4 pb-3">
                  Rückgabe
                </th>
                <th class="border-l-2 pl-4 pb-3">
                  Zustand
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="[id, option] in Object.entries(returns)" :key="id">
                <td>
                  <p>
                    {{ option.category }}: {{ formatAssetConsumableName(option.name, !!option.tag) }}
                    <template v-if="option.tag">
                      <br>
                      Asset Tag: {{ option.tag }}
                    </template>
                  </p>
                </td>
                <td class="border-l-2 pl-4">
                  <BaseRadioList
                    :box-container="false"
                    :options="statusOptions"
                    :selected="option.returned"
                    layout="horizontal"
                    @update:selected="returned => updateAssetConsumableReturn(id, {
                      ...option, returned: returned as AssetConsumableReturnStatus,
                    })"
                  />
                </td>
                <td class="border-l-2 pl-4">
                  <BaseRadioList
                    v-if="option.category === 'Consumable'"
                    :box-container="false"
                    :options="conditionOptions"
                    :selected="option.condition ?? undefined"
                    layout="horizontal"
                    @update:selected="condition => updateAssetConsumableReturn(id, {
                      ...option, condition: condition as AssetConsumableReturnCondition,
                    })"
                  />
                  <span v-else class="text-sm">
                    nicht anwendbar
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </BaseBox>
    </div>
    <div class="flex flex-col gap-8">
      <div v-if="noneReturned" class="flex items-center w-full p-4 bg-red-200 border-l-4 border-red-500 text-red-700 mt-1">
        <span>Bitte geben Sie für mindestens einen Gegenstand die Rückgabe & den Zustand an. </span>
      </div>
      <div v-else-if="allReturned" class="flex items-center w-full p-4 bg-green-200 border-l-4 border-green-500 text-green-700 mt-1">
        <span>Vollständiges Offboarding.</span>
      </div>
      <div v-else class="flex items-center w-full p-4 bg-yellow-200 border-l-4 border-yellow-500 text-yellow-700 mt-1">
        <span>Dies ist ein Teil-Offboarding, da nur für einen Teil der Gegenstände eine Rückgabe & ein Zustand angekreuzt wurde. Für einen Gegenstand muss immer die Rückgabe und, wenn anwendbar, der Zustand angekreuzt werden.</span>
      </div>
      <BackNextButtons
        v-if="hasNavigationButtons"
        :is-next-button-disabled="!canSubmit"
        @back="$emit('back')"
        @next="$emit('next')"
      />
    </div>
  </div>
</template>

<style scoped>
.Container.hasNavigationButtons{
  max-height: calc(100% - 72px);
}
</style>
