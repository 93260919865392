<script setup lang="ts">
import type { IconSize, IconType } from './Icon.vue'

type ButtonSize = 'small' | 'middle' | 'large'
type HtmlType = 'submit' | 'button'
type IconPosition = 'left' | 'right' | 'top'

const props = defineProps({
  type: {
    type: String,
    default: 'secondary',
    validator: (type: string) => ['primary', 'secondary', 'link'].includes(type),
  },
  htmlType: {
    type: String as PropType<HtmlType>,
    default: 'button',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String as PropType<IconType>,
    default: undefined,
  },
  iconPosition: {
    type: String as PropType<IconPosition>,
    default: 'left',
  },
  size: {
    type: String as PropType<ButtonSize>,
    default: 'large',
  },
  iconSize: {
    type: String as PropType<IconSize>,
    default: 'lg',
  },
})

defineEmits<{
  (e: 'click'): void
}>()

const iconType = computed(() => (!props.isLoading ? props.icon : undefined))
</script>

<template>
  <a-button
    class="BaseButton flex items-center justify-center"
    :class="type"
    :size="size"
    :html-type="htmlType"
    :loading="isLoading"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <base-text-with-icon :type="iconType" :icon-position="iconPosition" :icon-size="iconSize">
      <slot />
    </base-text-with-icon>
  </a-button>
</template>

<style scoped>
.BaseButton {
  @apply border-2 flex items-center font-semibold;
}
.BaseButton::before {
  @apply bg-transparent;
}
.BaseButton:hover {
  @apply bg-opacity-80;
}
.BaseButton.link {
  @apply border-0 flex items-center font-semibold text-primary p-0 bg-transparent;
}
.BaseButton.link:hover {
  @apply bg-transparent opacity-80;
}
.BaseButton.link:disabled {
  @apply text-neutral-500;
}
.BaseButton.primary {
  @apply text-white border-primary bg-primary;
}
.BaseButton.primary:disabled {
  @apply shadow-transparent text-neutral-200 border-neutral-300 bg-neutral-300;
}
.BaseButton.secondary {
  @apply text-primary border-primary bg-white;
}
.BaseButton.secondary:disabled {
  @apply shadow-transparent text-neutral-300 border-neutral-200 bg-neutral-100;
}
</style>
