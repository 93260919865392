<script setup lang="ts">
import type { DropdownOption } from './Dropdown.vue'
import Dropdown from './Dropdown.vue'

const props = defineProps({
  selectedOptions: {
    type: Array as PropType<DropdownOption[]>,
    default: () => [],
  },
  newTagPlaceholder: {
    type: String,
    default: 'Equipment hinzufügen',
  },
  ...Dropdown.props,
})

const emit = defineEmits<{
  (e: 'update:value', arg1: string | number | undefined): void
  (e: 'select', arg1: string | DropdownOption | undefined): void
  (e: 'deselect', arg1: string | number | undefined): void
  (e: 'focus'): void
  (e: 'blur'): void
}>()

function selectOption(value: string | DropdownOption) {
  emit('update:value', '')
  if (typeof value === 'string' && !value.trim().length) {
    return
  }
  const updatedOption = typeof value === 'string' ? { label: value, value } : value
  if (props.selectedOptions.map((option: DropdownOption) => option.label).includes(updatedOption.label)) {
    return
  }

  emit('select', updatedOption)
}

const filteredOption = computed(() => {
  const selectedOptionValues = props.selectedOptions.map((option: DropdownOption) => option.value)
  return props.options.filter((option: DropdownOption) => !selectedOptionValues.includes(option.value))
})

watch(
  () => props.value,
  () => {
    const value = props.value
    if (value.includes(',')) {
      const tokenizedValue = value.split(',').filter((val: string) => val.length)
      tokenizedValue.forEach((val: string) => {
        selectOption(val)
      })
    }
  }
)
</script>

<template>
  <label class="MultiDropdown max-h-full h-full flex gap-2 flex-col">
    <div>
      <div v-if="label" class="font-bold text-sm mb-1">
        {{ label }}
      </div>
      <div class="flex flex-wrap gap-2">
        <template v-for="option in selectedOptions" :key="option.value">
          <a-tag closable class="text-sm py-1 px-2 bg-white border-2 m-0" @close="$emit('deselect', option)">
            <template #closeIcon><BaseIcon type="close" size="sm" /></template>
            {{ option.label }}
          </a-tag>
        </template>
        <a-tag
          v-if="value"
          class="text-sm py-1 px-2 border-2 cursor-pointer"
          :class="{ 'bg-primary border-primary text-white': !!value }"
          :disabled="!!value"
          @click="selectOption(value)"
        >
          {{ value ? `"${value}"` : '' }} {{ newTagPlaceholder }}
        </a-tag>
      </div>
    </div>
    <Dropdown
      :options="filteredOption"
      :is-loading="isLoading"
      :value="value"
      :disabled="disabled"
      :placeholder="placeholder"
      @select="selectOption($event)"
      @update:value="$emit('update:value', $event)"
      @keyup.enter="selectOption(value)"
    />
  </label>
</template>

<style scoped>
.Focused:not(.Error),
.ant-select:not(.ant-select-disabled).Error:hover {
  @apply border-primary;
}
.Error {
  @apply border-red-500;
}
.MultiDropdown :deep(.ant-tag-close-icon),
.MultiDropdown :deep(.ant-tag-close-icon svg) {
  @apply !inline-block text-sm text-primary ml-0.5 -mt-0.5;
}
</style>
