import type { EquipmentRentalsDetail } from '~/server/api/equipment/rentals.get'

export function formatEquipmentRentalsDetailToText(equipmentRentals: EquipmentRentalsDetail['rentals'] = []) {
  return equipmentRentals.map((equipmentRental: EquipmentRentalsDetail['rentals'][number]) => {
    if (equipmentRental.serial) {
      return `- ${equipmentRental.type} "${equipmentRental.title}" mit Serial "${equipmentRental.serial}"`
    }
    return `- ${equipmentRental.type} "${equipmentRental.title}"`
  }).join('\n')
}

export function generateRentSummary(employeeName: string, equipmentRentals?: EquipmentRentalsDetail['rentals']): string {
  const employeeInfo = `MitarbeiterIn "${employeeName}"`
  if (!equipmentRentals || equipmentRentals?.length < 1) {
    return `
      ${employeeInfo}
      Keine Geräte zum Mieten ausgewählt.
    `
  }
  return `
    ${employeeInfo} hat folgendes Ausleihequipment erhalten:
    ${formatEquipmentRentalsDetailToText(equipmentRentals)}
  `
}

export function generateReturnSummary(employeeName: string, equipmentRentalsReturning: EquipmentRentalsDetail['rentals'], equipmentRentalsRented: EquipmentRentalsDetail['rentals'], isEmployeeActive = true): string {
  const employeeInfo = isEmployeeActive ? `MitarbeiterIn "${employeeName}"` : `Inaktiver MitarbeiterIn "${employeeName}"`
  const returnedText = `${employeeInfo} hat folgendes Equipment zurückgegeben:\n`

  if (!equipmentRentalsReturning || equipmentRentalsReturning.length < 1) {
    return `
      ${employeeInfo}
      Keine Geräte zur Rückgabe ausgewählt.
    `
  }

  const equipmentRentalsReturningIds = equipmentRentalsReturning.map(e => e.id)
  const leftoverEquipment = equipmentRentalsRented.filter(e => !equipmentRentalsReturningIds.includes(e.id))
  const equipmentRentalsReturningText = formatEquipmentRentalsDetailToText(equipmentRentalsReturning.map(equipment => ({ ...equipment })))
  const leftoverEquipmentText = formatEquipmentRentalsDetailToText(leftoverEquipment.map(equipment => ({ ...equipment })))

  if (leftoverEquipment.length < 1) {
    return `
      ${returnedText}
      ${equipmentRentalsReturningText}
    `
  }

  return `
    ${returnedText}
    ${equipmentRentalsReturningText}
    Noch Offen:
    ${leftoverEquipmentText}
  `
}
