export default function calculatePageSize() {
  const height = window.innerHeight
  if (height > 800) {
    return 10 // Large screens
  }
  else if (height > 600) {
    return 8 // Medium screens
  }
  else {
    return 5 // Small screens
  }
}
