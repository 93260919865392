<script setup lang="ts">
export declare interface DropdownOption {
  label: string
  value: string
  isSemiTransparent?: boolean
}

const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: undefined,
  },
  placeholder: {
    type: String,
    default: undefined,
  },
  options: {
    type: Array as PropType<DropdownOption[]>,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits<{
  (e: 'update:value', arg1: string): void
  (e: 'select', arg1: DropdownOption): void
}>()

const filteredOptions = computed(() => {
  if (!props.value) {
    return props.options
  }
  return props.options.filter((option) => {
    const lowerValue = props.value.toLocaleLowerCase()
    return (
      option.label.toLocaleLowerCase().includes(lowerValue) || option.value.toLocaleLowerCase().includes(lowerValue)
    )
  })
})

function selectItem(option: DropdownOption) {
  emit('update:value', option.label)
  emit('select', option)
}
</script>

<template>
  <div class="w-full h-full flex flex-col justify-items max-h-full">
    <base-input
      :value="value"
      :label="label"
      :is-focused="!disabled"
      :disabled="disabled"
      :placeholder="placeholder"
      class="w-full flex-grow-0"
      @update:value="$emit('update:value', $event)"
    />
    <div
      class="DropdownContainer"
      :class="{
        'overflow-y-scroll': !disabled,
        'bg-neutral-100 overflow-y-hidden': disabled,
        'flex items-center justify-center': filteredOptions.length === 0 || isLoading,
      }"
    >
      <div v-if="isLoading">
        <base-text-with-icon type="spin" icon-position="left" class="text-lg font-normal text-neutral-400">
          Lade ...
        </base-text-with-icon>
      </div>
      <ul v-else-if="filteredOptions.length > 0" class="mb-0">
        <li
          v-for="item in filteredOptions"
          :key="item.value"
          class="w-full text-base text-primary"
          :class="{ 'opacity-60': item.isSemiTransparent ?? false }"
        >
          <button
            class="w-full text-start px-3 py-2"
            :class="{ SelectedRow: item.label === value }"
            :disabled="disabled"
            @click="selectItem(item)"
          >
            {{ item.label }}
          </button>
        </li>
      </ul>
      <div v-else class="text-lg font-normal text-neutral-400">
        Keine Daten gefunden
      </div>
    </div>
  </div>
</template>

<style scoped>
::-webkit-scrollbar {
  @apply w-10;
}
::-webkit-scrollbar-track {
  @apply bg-neutral-100;
}
::-webkit-scrollbar-thumb {
  @apply bg-neutral-400;
}
.DropdownContainer {
  @apply w-full flex-1 border border-t-0 bg-white;
}
.DropdownContainer li button:hover:not(:disabled),
.SelectedRow {
  @apply bg-primary/60 text-white;
}
button:disabled {
  @apply cursor-not-allowed text-neutral-400;
}
</style>
