import type { FlowConfig } from '../types'

const flow: FlowConfig = {
  id: 'onboarding-disabled',
  icon: 'onboard',
  title: 'Onboarding',
  tileTitle: 'Onboarding starten',
}

export default flow
