import { debounce } from 'lodash'
import type { FlowConfig, FlowContext } from '../types'
import Dropdown from '~/components/base/Dropdown.vue'
import Options from '~/components/step/Options.vue'
import ThankYouScreen from '~/components/layout/ThankYouScreen.vue'
import Tiles from '~/components/base/Tiles.vue'
import ConfirmationStep from '~/components/auth/ConfirmationStep.vue'
import Freetext from '~/components/step/Freetext.vue'
import executeAPI from '~/helpers/executeAPI'
import type { EquipmentOption } from '~/server/database/entities/EquipmentOption'
import { generateRentSummary, generateReturnSummary } from '~/helpers/format/rentals'
import { formatEmployeeIntoIdAndName } from '~/helpers/format/employee'

export function getEquipmentOptionById(equipmentOptions: EquipmentOption[], id: string) {
  return equipmentOptions.find(equipmentOption => equipmentOption.id === id)
}

function capitalizeWorld(word: string) {
  return word[0].toUpperCase() + word.slice(1)
}

const { session } = useAuthStore()

const flow: FlowConfig = {
  id: 'equipment',
  icon: 'equipment',
  title: 'Ausleihequipment',
  tileTitle: 'Equipment ausleihen/zurückgeben',
  steps: [
    {
      id: 'employees',
      component: Dropdown,
      isKeyboardShown: true,
      onComponentEvents: {
        'mounted': async (context: FlowContext) => {
          const { employees } = await executeAPI('/api/employees/search?includeInactive=true')
          context.store('employees', employees)
          context.store('lastInput', '')
        },
        'update:value': debounce(async (context: FlowContext, input: string) => {
          context.store('lastInput', encodeURIComponent(input))
          const { employees, text } = await executeAPI(
            `/api/employees/search?includeInactive=true&text=${encodeURIComponent(input)}`
          )
          if (context.values.lastInput === text) {
            context.store('employees', employees)
          }
        }, 300),
        'select': async (context: FlowContext, value: any) => {
          const currentEmployee = context.values.employees.find((employee: any) => employee.id === value.value)
          context.store('currentEmployee', currentEmployee)
          const { rentals } = await executeAPI(
            `/api/equipment/rentals?employeeId=${context?.values?.currentEmployee?.id}`
          )
          context.store('rentedEquipments', rentals)

          if (rentals?.length === 0) {
            if (currentEmployee.isActive) {
              context.navigate('select-equpment-to-rent')
            }
            else {
              context.navigate('employee-inactive')
            }
          }
          else {
            context.navigate('rent-or-return')
          }
        },
      },
      getComponentProps: (context: FlowContext) => ({
        value: context?.values?.employees?.label,
        label: 'Bitte gebe den Mitarbeiter Namen an',
        placeholder: 'Bitte gebe den Mitarbeiter Namen an',
        options: context?.values?.employees
          ? context?.values?.employees.map((employee: any) => ({
            label: formatEmployeeIntoIdAndName(employee),
            value: employee?.id,
            isSemiTransparent: !employee?.isActive,
          }))
          : [],
      }),
    },
    {
      id: 'employee-inactive',
      component: Freetext,
      onComponentEvents: {
        back: (context: FlowContext) => {
          context.navigate('employees')
        },
      },
      getComponentProps: (context: FlowContext) => ({
        title: 'Der folgende Fehler ist aufgetreten',
        text: `Mitarbeiter "${context?.values?.currentEmployee?.fullName}" ist als inaktiv registriert und kann leider keine Geräte mehr ausleihen`,
        isNextButtonVisible: false,
        backButtonText: 'Zurück',
      }),
    },
    {
      id: 'rent-or-return',
      component: Tiles,
      onComponentEvents: {
        click: (context: FlowContext, tileId) => {
          if (tileId === 'select-equpment-to-rent' && !context?.values?.currentEmployee?.isActive) {
            context.navigate('employee-inactive')
            return
          }
          context.navigate(tileId)
        },
      },
      getComponentProps: () => ({
        allTiles: [
          { id: 'select-equpment-to-rent', title: 'Equipment ausleihen', icon: 'equipment', disabled: false },
          { id: 'return-selection', title: 'Equipment zurückgeben', icon: 'back', disabled: false },
        ],
      }),
    },
    {
      id: 'select-equpment-to-rent',
      component: Options,
      onComponentEvents: {
        mounted: async (context: FlowContext) => {
          const rentalOptions: EquipmentOption[] = await executeAPI(`/api/equipment/options?locationId=${session.value.location.id}`)
          context.store(
            'rentalOptions',
            rentalOptions.filter(option => option.active)
          )
          context.store('selectedEquipments', [])
        },
        select: (context: FlowContext, value: any) => {
          context.store('selectedEquipments', value)
        },
        back: (context: FlowContext) => {
          context.navigate('employees')
        },
        next: (context: FlowContext) => {
          context.navigate('rent-confirmation')
        },
      },
      getComponentProps: (context: FlowContext) => ({
        type: 'checklist',
        value: context?.values?.rentalOptions?.label,
        label: 'Bitte wähle die Gegenstände aus, die du ausleihen wirst',
        placeholder: 'Bitte wähle das Headset Model aus',
        isNextButtonDisabled: !(context?.values?.selectedEquipments?.length > 0),
        nextButtonText: context?.values?.selectedEquipments?.length
          ? `Bestätige ${context?.values?.selectedEquipments?.length} ausgewählte Gegenstände`
          : 'Wähle mindestens einen Gegenstand aus',
        selected: context.values.selectedEquipments,
        options: context?.values?.rentalOptions
          ? context?.values?.rentalOptions.map((equipment: any) => ({
            label: `${capitalizeWorld(equipment.type)} "${equipment?.title}"`,
            value: equipment?.id,
          }))
          : [],
      }),
    },
    {
      id: 'rent-confirmation',
      component: ConfirmationStep,
      onComponentEvents: {
        mounted: (context: FlowContext) => {
          const requestToRentEquipment = {
            employeeId: context?.values?.currentEmployee?.id,
            rentals: context?.values?.selectedEquipments.map((equipmentId: string) => {
              const equipmentOption = getEquipmentOptionById(context.values.rentalOptions, equipmentId)
              return {
                equipmentId: equipmentOption?.id,
                ...equipmentOption,
              }
            }),
          }
          context.store('requestToRentEquipment', requestToRentEquipment)
          context.store(
            'rentSummary',
            generateRentSummary(
              context?.values?.currentEmployee?.fullName,
              context?.values?.requestToRentEquipment?.rentals
            )
          )
        },
        confirm: async (context: FlowContext, cardId: string) => {
          await executeAPI('/api/equipment/rentals', {
            method: 'POST',
            data: context?.values?.requestToRentEquipment,
            cardId,
          })
          context.navigate('rent-thanks')
        },
        back: (context: FlowContext) => {
          context.navigate('select-equpment-to-rent')
        },
      },
      getComponentProps: (context: FlowContext) => ({
        summary: context.values?.rentSummary || '',
      }),
    },
    {
      id: 'rent-thanks',
      component: ThankYouScreen,
      getComponentProps: (context: FlowContext) => ({
        summary: context.values?.rentSummary || '',
      }),
    },
    // return equipment
    {
      id: 'return-selection',
      component: Options,
      onComponentEvents: {
        mounted: (context: FlowContext) => {
          context.store('selectedEquipmentIdsToReturn', [])
        },
        select: (context: FlowContext, value: any) => {
          context.store('selectedEquipmentIdsToReturn', value)
        },
        back: (context: FlowContext) => {
          context.navigate('employees')
        },
        next: (context: FlowContext) => {
          const rentalsThatWillBeReturned = context?.values?.selectedEquipmentIdsToReturn.map((id: string) => {
            return context?.values?.rentedEquipments.find((rentedEquipment: any) => rentedEquipment.id === id)
          })
          context.store('rentalsThatWillBeReturned', rentalsThatWillBeReturned)
          context.navigate('return-confirmation')
        },
      },
      getComponentProps: (context: FlowContext) => ({
        type: 'checklist',
        isNextButtonDisabled: !(context?.values?.selectedEquipmentIdsToReturn?.length > 0),
        nextButtonText: context?.values?.selectedEquipmentIdsToReturn?.length
          ? `Bestätige ${context?.values?.selectedEquipmentIdsToReturn?.length} ausgewählte Gegenstände`
          : 'Wähle mindestens einen Gegenstand aus',
        label: 'Bitte wähle die Gegenstände aus, die du zurückgeben wirst',
        selected: context.values.selectedEquipmentIdsToReturn,
        options: context?.values?.rentedEquipments
          ? context?.values?.rentedEquipments.map((equipment: any) => ({
            label: `${capitalizeWorld(equipment.type)} "${equipment?.title}"`,
            value: equipment?.id,
          }))
          : [],
      }),
    },
    {
      id: 'return-confirmation',
      component: ConfirmationStep,
      onComponentEvents: {
        mounted: (context: FlowContext) => {
          context.store(
            'returnSummary',
            generateReturnSummary(
              context?.values?.currentEmployee?.fullName,
              context?.values?.rentalsThatWillBeReturned,
              context?.values?.rentedEquipments
            ) || ''
          )
        },
        confirm: async (context: FlowContext, cardId: string) => {
          await executeAPI('/api/equipment/rentals', {
            method: 'DELETE',
            data: context?.values?.selectedEquipmentIdsToReturn,
            cardId,
          })
          context.navigate('return-thanks')
        },
        back: (context: FlowContext) => {
          context.navigate('return-selection')
        },
      },
      getComponentProps: (context: FlowContext) => ({
        summary: context.values?.returnSummary || '',
      }),
    },
    {
      id: 'return-thanks',
      component: ThankYouScreen,
      getComponentProps: (context: FlowContext) => ({
        summary: context.values?.returnSummary || '',
      }),
    },
  ],
}

export default flow
