import type { FlowConfig, FlowContext } from '../types'
import ConfirmationStep from '~/components/auth/ConfirmationStep.vue'
import ThankYouScreen from '~/components/layout/ThankYouScreen.vue'
import Freetext from '~/components/step/Freetext.vue'
import executeAPI from '~/helpers/executeAPI'
import type { EmployeeUpdateCounts } from '~/server/employeeParser'

const flow: FlowConfig = {
  id: 'updateEmployees',
  icon: 'cycle',
  title: 'Mitarbeiterliste aktualisieren',
  tileTitle: 'Mitarbeiterliste aktualisieren',
  isAdmin: true,
  steps: [
    {
      id: 'confirmation',
      component: ConfirmationStep,
      isKeyboardShown: false,
      onComponentEvents: {
        confirm: async (context: FlowContext, value: any) => {
          const result = await executeAPI('api/employees/update', { method: 'GET', cardId: value })
          if (result.status === 'Ok') {
            const {
              employeeWithoutAxaWindowsIdCount,
              updatedEmployeeCount,
              employeeWithoutEmailCount,
            }: EmployeeUpdateCounts = result.data
            context.store(
              'thanksSummary',
              `Die Mitarbeiterliste aus der CSV/XLSX Datei wurde extrahiert:
            - ${updatedEmployeeCount} Mitarbeiter wurden aktualisiert (${employeeWithoutEmailCount} Mitarbeiter besitzen keine E-Mail Adresse)
            - ${employeeWithoutAxaWindowsIdCount} Mitarbeiter konnten aufgrund fehlender Axa Windows ID nicht aktualisiert werden`
            )

            context.navigate('thanks')
          }
          else {
            context.store('error', result.message)
            context.navigate('error')
          }
        },
        back: (context: FlowContext) => {
          context.navigateByFlowId('admin')
        },
      },
      getComponentProps: () => {
        return {
          summary: 'Die Mitarbeiterliste aus der CSV/XLSX Datei extrahieren',
        }
      },
    },
    {
      id: 'error',
      component: Freetext,
      onComponentEvents: {
        back: (context: FlowContext) => {
          context.navigate('confirmation')
        },
      },
      getComponentProps: (context: FlowContext) => ({
        title: 'Der folgende Fehler ist aufgetreten',
        text: context.values.error || '',
        isNextButtonVisible: false,
        backButtonText: 'Nochmal versuchen',
      }),
    },
    {
      id: 'thanks',
      component: ThankYouScreen,
      getComponentProps: (context: FlowContext) => ({
        summary: context.values.thanksSummary || '',
        notificationDelaySeconds: 10,
      }),
    },
  ],
}

export default flow
