<script setup lang="ts">
const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: 'Ausweis Authentifizierung',
  },
  description: {
    type: String,
    default: 'Bitte scanne Deinen Mitarbeiterausweis um zu bestätigen',
  },
})

const emit = defineEmits<{
  (e: 'submit', arg1: string): void
}>()

const value = ref('')
const input = ref()
let interval: ReturnType<typeof setInterval>

const focusItself = function () {
  if (input.value) {
    input.value.focus()
  }
}
const submit = function () {
  if (!props.isLoading) {
    emit('submit', value.value)
  }
  value.value = ''
}

onMounted(() => {
  focusItself()
  if (interval) {
    clearInterval(interval)
  }
  interval = setInterval(focusItself, 500)
})
onUnmounted(() => {
  if (interval) {
    clearInterval(interval)
  }
})
</script>

<template>
  <base-box :title="title" class="BaseBoxContainer" :class="{ Inactive: isLoading }" :is-active="!isLoading">
    <div class="flex h-full w-full justify-center items-center p-4 gap-x-4 text-lg font-medium">
      <base-icon type="credit" size="xxxl" class="mt-3" />
      <span class="h-fit w-48 flex-grow-0 relative">
        <span :class="{ invisible: isLoading }">{{ description }}</span>
        <div v-if="isLoading" class="absolute inset-0 flex items-center">
          <BaseLoading />
        </div>
      </span>
    </div>
    <form class="fixed -top-96 -left-96" @submit.prevent="submit">
      <input ref="input" v-model="value" type="password" data-testid="cardInput" @blur="focusItself">
    </form>
  </base-box>
</template>

<style scoped>
.BaseBoxContainer {
  @apply w-full h-full flex flex-col;
}
.BaseBoxContainer.Inactive :deep(.Icon),
.BaseBoxContainer.Inactive span {
  @apply text-neutral-400;
}
</style>
