import type { FlowConfig } from '../types'
import configurationFlow from './configurationFlow'
import onboardingFlow from './onboardingFlow'
import offboardingFlow from './offboardingFlow'
import disabledOnboardingFlow from './disabledOnboardFlow'
import equipmentFlow from './equipmentFlow'
import supportFlow from './supportFlow'
import refundFlow from './refundFlow'
import helpFlow from './helpFlow'
import adminFlow from './adminFlow'
import manageUser from './manageUserFlow'
import manageEquipment from './manageEquipmentFlow'
import statisticsFlow from './statisticsFlow'
import updateEmployeesFlow from './updateEmployeesFlow'
import exportOffboardingRentalsFlow from './exportOffboardingRentalsFlow'
import exportOverdueRentalsFlow from './exportOverdueRentalsFlow'

const flows: FlowConfig[] = [
  configurationFlow,
  onboardingFlow,
  offboardingFlow,
  disabledOnboardingFlow,
  equipmentFlow,
  supportFlow,
  refundFlow,
  helpFlow,
  adminFlow,
  manageUser,
  manageEquipment,
  statisticsFlow,
  updateEmployeesFlow,
  exportOffboardingRentalsFlow,
  exportOverdueRentalsFlow,
]

export default flows
