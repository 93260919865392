import type { FlowConfig, FlowContext } from '../types'
import ConfirmationStep from '~/components/auth/ConfirmationStep.vue'
import ThankYouScreen from '~/components/layout/ThankYouScreen.vue'
import executeAPI from '~/helpers/executeAPI'

const config = useRuntimeConfig()
const EMAIL_RECEIVER_OFFBOARDED_ASSETS = config.public.emailReceiverOffboardedAssets

const flow: FlowConfig = {
  id: 'exportOffboardingRentals',
  icon: 'email',
  title: 'Offboardingliste - Mail senden',
  tileTitle: 'Offboardingliste - Mail senden',
  isAdmin: true,
  steps: [
    {
      id: 'confirm-offborading-rentals-export',
      component: ConfirmationStep,
      isKeyboardShown: false,
      onComponentEvents: {
        confirm: async (context: FlowContext, value: any) => {
          const result = await executeAPI('api/email/offboardings', { data: undefined, method: 'GET', cardId: value })
          if (result.emailSent) {
            context.store('summary', 'Die E-Mail wurde erfolgreich gesendet.')
          }
          else {
            context.store('summary', 'Das Versenden der E-Mail wurde übersprungen, da die Offboardingliste leer ist.')
          }
          context.navigate('thanks')
        },
      },
      getComponentProps: () => ({
        summary: `Die Offboardingliste per Mail an ${EMAIL_RECEIVER_OFFBOARDED_ASSETS} versenden.`,
      }),
    },
    {
      id: 'thanks',
      component: ThankYouScreen,
      getComponentProps: (context: FlowContext) => ({
        summary: context.values.summary || '',
      }),
    },
  ],
}

export default flow
