import { format } from 'date-fns'
import type { EquipmentRentalWithEquipmentOption } from '~/server/database/entities/EquipmentRental'

export function formatDate(date: string | number | Date, dateFormat = 'dd.MM.yyyy HH:mm') {
  return format(new Date(date), dateFormat)
}

export declare interface EquipmentRentalStat extends EquipmentRentalWithEquipmentOption {
  employeeName: string
}
