<script setup lang="ts">
defineProps({
  summary: {
    type: String,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['confirm', 'back'])

const { session } = useAuthStore()
</script>

<template>
  <div class="flex flex-col gap-4">
    <BaseBox
      title="Bitte bestätige folgende Aktion"
      class="w-full"
      icon="edit"
      :disabled="isLoading"
      is-highlited
      @icon="$emit('back')"
    >
      <BaseFormatSummary :summary="summary" />
    </BaseBox>
    <BaseBox class="w-full">
      Eingeloggt am Standort "{{ session.location.title }}"
    </BaseBox>
    <AuthCard :is-loading="isLoading" @submit="$emit('confirm', $event)" />
  </div>
</template>
