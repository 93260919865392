<script setup lang="ts">
const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  username: {
    type: String,
    default: '',
  },
  password: {
    type: String,
    default: '',
  },
  showBackButton: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['submit', 'back', 'update:value'])

const currentlyActiveInput = ref()
const localUsername = ref(props.username)
const localPassword = ref(props.password)
const areFieldsEmpty = computed(() => !localUsername.value || !localPassword.value)

watch([localUsername, localPassword, currentlyActiveInput], () => {
  if (currentlyActiveInput.value === 'username') {
    emit('update:value', localUsername.value)
  }
  else if (currentlyActiveInput.value === 'password') {
    emit('update:value', localPassword.value)
  }
  else {
    emit('update:value')
  }
})
watch(
  () => props.value,
  () => {
    if (currentlyActiveInput.value === 'username') {
      localUsername.value = props.value
    }
    else if (currentlyActiveInput.value === 'password') {
      localPassword.value = props.value
    }
  }
)
</script>

<template>
  <form
    class="flex w-full h-full gap-x-6"
    @submit.prevent="$emit('submit', { username: localUsername, password: localPassword })"
  >
    <div class="FormColumn">
      <BaseInput
        v-model:value="localUsername"
        label="Login"
        placeholder="Service Point Mitarbeiter Login"
        type="text"
        is-focused
        @focus="currentlyActiveInput = 'username'"
        @blur="currentlyActiveInput = undefined"
      />
      <BaseButton v-if="showBackButton" icon="credit" @click="$emit('back')">
        Zurück zur Bestätigung
      </BaseButton>
    </div>
    <div class="FormColumn">
      <BaseInput
        v-model:value="localPassword"
        label="Passwort"
        placeholder="Service Point Mitarbeiter Passwort"
        type="password"
        @focus="currentlyActiveInput = 'password'"
        @blur="currentlyActiveInput = undefined"
      />
      <BaseButton
        :is-icon-first="true"
        :is-loading="isLoading"
        :disabled="areFieldsEmpty"
        type="primary"
        icon="badge"
        html-type="submit"
      >
        Anmelden
      </BaseButton>
    </div>
  </form>
</template>

<style scoped>
.FormColumn {
  @apply flex flex-col flex-grow gap-y-6;
}
</style>
