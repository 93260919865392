import type { FlowConfig } from '../types'

const flow: FlowConfig = {
  id: 'support',
  icon: 'support',
  title: 'Support beantragen',
  tileTitle: 'Support beantragen',
}

export default flow
