<script setup lang="ts">
defineProps({
  isBackButtonDisabled: {
    type: Boolean,
    default: false,
  },
  isNextButtonDisabled: {
    type: Boolean,
    default: false,
  },
  nextButtonText: {
    type: String,
    default: 'Nächster Schritt',
  },
  backButtonText: {
    type: String,
    default: 'Vorheriger Schritt',
  },
  isBackButtonVisible: {
    type: Boolean,
    default: true,
  },
  isNextButtonVisible: {
    type: Boolean,
    default: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits<{
  (e: 'next'): void
  (e: 'back'): void
}>()
</script>

<template>
  <div class="flex justify-between space-x-10">
    <base-button
      v-if="isBackButtonVisible"
      :disabled="isBackButtonDisabled"
      type="secondary"
      icon="left"
      class="w-full"
      @click="emit('back')"
    >
      {{ backButtonText }}
    </base-button>
    <base-button
      v-if="isNextButtonVisible"
      :disabled="isNextButtonDisabled"
      type="primary"
      icon="right"
      icon-position="right"
      class="w-full"
      @click="emit('next')"
    >
      {{ nextButtonText }}
    </base-button>
  </div>
</template>
