import type { AssetConsumableCreateReturn } from '~/server/database/entities/AssetConsumableReturn'

function formatAssetConsumableToText(items: AssetConsumableCreateReturn[]) {
  return items.map(item => `- ${item.category}: ${item.name}`).join('\n')
}
function formatAssetConsumableForEmailTemplate(items: AssetConsumableCreateReturn[]): string {
  return items.map((item) => {
    const baseInfo = `${item.category} “${item.name}”`
    const tagInfo = item.tag ? `, Asset Tag: ${item.tag}` : ''
    const conditionInfo = item.condition ? `, Zustand: ${item.condition}` : ''
    return `<li>${baseInfo}${tagInfo}${conditionInfo}</li>`
  }).join('')
}

export function generateAssetConsumablesReturnSummary(
  employeeName: string,
  isEmployeeActive: boolean,
  assetConsumablesToReturn: AssetConsumableCreateReturn[],
  returnNote?: string,
  confirmationEmail?: string,
  asHtmlTemplate?: boolean
): string {
  const employeeInfo = isEmployeeActive ? `MitarbeiterIn "${employeeName}"` : `Inaktiver MitarbeiterIn "${employeeName}"`
  const returnedText = asHtmlTemplate ? `<p>dies ist die Bestätigungsmail zum Offboarding. Sie haben folgendes Equipment</p>` : `${employeeInfo} hat folgendes Equipment\n`

  if (!assetConsumablesToReturn || assetConsumablesToReturn.length < 1) {
    return `
      ${employeeInfo}
      Keine Geräte zur Rückgabe ausgewählt.
    `
  }

  const returnedItems = assetConsumablesToReturn.filter(({ returned }) => returned === 'yes')
  const notReturnedItems = assetConsumablesToReturn.filter(({ returned }) => returned === 'no')
  const returningLaterItems = assetConsumablesToReturn.filter(({ returned }) => returned === 'later')

  const texts = {
    returned: '',
    notReturned: '',
    returningLater: '',
    returnNote: '',
    confirmationEmail: '',
  }

  if (returnedItems.length) {
    texts.returned = asHtmlTemplate
      ? `<p><strong>zurückgegeben:</strong></p><ul>${formatAssetConsumableForEmailTemplate(returnedItems)}</ul>`
      : `zurückgegeben:
      ${formatAssetConsumableToText(returnedItems)}
    `
  }

  if (notReturnedItems.length) {
    texts.notReturned = asHtmlTemplate
      ? `<p><strong>nicht zurückgegeben:</strong></p><ul>${formatAssetConsumableForEmailTemplate(notReturnedItems)}</ul>`
      : `nicht zurückgegeben:
      ${formatAssetConsumableToText(notReturnedItems)}
    `
  }

  if (returningLaterItems.length) {
    texts.returningLater = asHtmlTemplate
      ? `<p><strong>als wird nachgeliefert angegeben:</strong></p><ul>${formatAssetConsumableForEmailTemplate(returningLaterItems)}</ul>`
      : `als wird nachgeliefert angegeben:
      ${formatAssetConsumableToText(returningLaterItems)}
    `
  }

  if (returnNote) {
    texts.returnNote = asHtmlTemplate
      ? `<p><strong>Weitere Details:</strong> ${returnNote}</p>`
      : `Weitere Details:
      ${returnNote}
    `
  }

  if (confirmationEmail) {
    texts.confirmationEmail = `
      Eine Bestätigungsmail wird an "${confirmationEmail}" versendet.
    `
  }

  return `
    ${returnedText}
    ${texts.returned}
    ${texts.notReturned}
    ${texts.returningLater}
    ${texts.returnNote}
    ${texts.confirmationEmail}
  `
}
