import type { FlowConfig } from '../types'

const flow: FlowConfig = {
  id: 'refund',
  icon: 'calculation',
  title: 'Fahrtkosten einreichen',
  tileTitle: 'Fahrtkosten einreichen',
}

export default flow
