import type { FlowConfig, FlowContext } from '../types'
import Dropdown from '~/components/base/Dropdown.vue'
import ThankYouScreen from '~/components/layout/ThankYouScreen.vue'
import LoginStep from '~/components/auth/LoginStep.vue'
import executeAPI from '~/helpers/executeAPI'

const flow: FlowConfig = {
  id: 'configuration',
  title: 'Anmeldung',
  icon: 'settings',
  tileTitle: 'Anmeldung starten',
  steps: [
    {
      id: 'location',
      component: Dropdown,
      isKeyboardShown: true,
      onComponentEvents: {
        mounted: async (context: FlowContext) => {
          const locations = await executeAPI('/api/locations')
          context.store('locations', locations)
        },
        select: (context: FlowContext, value: any) => {
          context.store('location', value)
          context.navigate('confirmation')
        },
      },
      getComponentProps: (context: FlowContext) => ({
        value: context?.values?.location?.label,
        label: 'Bitte gebe deinen Standort an',
        placeholder: 'Tippe um den Standort zu filtern',
        options: context?.values?.locations
          ? context?.values?.locations.map((location: any) => ({ label: location?.title, value: location?.id }))
          : [],
      }),
    },
    {
      id: 'confirmation',
      component: LoginStep,
      isKeyboardShown: true,
      onComponentEvents: {
        back: (context: FlowContext) => {
          context.navigate('location')
        },
        submit: async (context: FlowContext, { username, password }: { username: string, password: string }) => {
          const { login } = useAuthStore()
          await login(username, password, context?.values?.location?.value)
          context.navigate('thankyou')
        },
      },
      getComponentProps: (context: FlowContext) => ({
        summary: `Neue Anmeldung am Standort "${context?.values?.location?.label}"`,
      }),
    },
    {
      id: 'thankyou',
      component: ThankYouScreen,
      getComponentProps: (context: FlowContext) => ({
        summary: `Neue Anmeldung am Standort "${context?.values?.location?.label}"`,
      }),
    },
  ],
}

export default flow
